import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [0,3,4,6,7,8,9,10,11];

export const dictionary = {
		"/": [12],
		"/audio": [14,[3]],
		"/audio/bedtime_story/home": [15,[3]],
		"/audio/error": [16,[3]],
		"/audio/mindfulness/home": [17,[3]],
		"/health/error": [18,[4]],
		"/health/food-preferences": [19,[4]],
		"/health/meal-info": [20,[4]],
		"/health/meal-info/error": [21,[4]],
		"/health/snap-permission": [23,[4]],
		"/health/snap": [22,[4]],
		"/horoscope": [~24,[5]],
		"/hydration-tracker": [~25,[6]],
		"/hydration-tracker/pref": [26,[6,7]],
		"/movie/error": [27,[8]],
		"/movie/home/[page]": [28,[8]],
		"/movie/recommendation": [29,[8]],
		"/music/artist/[artistId]": [~30,[9]],
		"/music/artist/[artistId]/album/[albumId]": [~31,[9]],
		"/music/playlist/[playlistId]": [~32,[9]],
		"/recipe": [~33,[10]],
		"/recipe/error": [34,[10]],
		"/recipe/recipe-details/[dish]": [35,[10]],
		"/recipe/search": [36,[10]],
		"/sports/nba": [~37,[11]],
		"/sports/nba/error": [38,[11]],
		"/sports/nba/games": [39,[11]],
		"/(btw-path-handler)/[region]/[lang]/BTW/[slugId]": [~13,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';