import { PUBLIC_ENV } from '$env/static/public';
import { GENERIC_ERROR } from '$lib/constants/text';
import { sendAnalyticsEvent } from '$lib/services/analytics';
import { AnalyticsEventAction } from '$lib/services/analytics/enums';
import Logger from '$lib/utils/logger';
import * as Sentry from '@sentry/sveltekit';
import { SENTRY_DSN } from './lib/constants/index';

import {
	onCLS,
	onFCP,
	onLCP,
	type CLSMetric,
	type FCPMetric,
	type LCPMetric
} from 'web-vitals/attribution';
import type { HandleClientError } from '@sveltejs/kit';

function sendVitalsToServer(data: CLSMetric | LCPMetric | FCPMetric) {
	const finalVal = (data?.value / 1000).toFixed(2);
	Logger.info(
		`%c🚀 vital report: ${data?.name} is ${finalVal} 🚀`,
		`color:${Number(finalVal) > 2 ? '#FFBB64' : '#99BC85'}; font-size:14px`
	);
	sendAnalyticsEvent(AnalyticsEventAction.WEB_VITAL, {
		section_name: data.name,

		ext: {
			data: data
		}
	});
}

Sentry.init({
	dsn: SENTRY_DSN,
	sampleRate: 0.1,
	tracesSampleRate: 0.1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	integrations: [Sentry.replayIntegration()],
	// beforeSend(event, hint) {
	// 	if (hint?.originalException instanceof SyntaxError) {
	// 		Logger.warn('Filtered out SyntaxError:', hint.originalException);
	// 		return null;
	// 	}

	// 	if (hint?.originalException instanceof TypeError) {
	// 		Logger.warn('Filtered out TypeError:', hint.originalException);
	// 		return null;
	// 	}

	// 	return event;
	// },
	environment: PUBLIC_ENV
});

export const handleError = (async ({ error, event }) => {
	const errorId = crypto.randomUUID();
	Logger.log('error client', error);
	Sentry.captureException(error, {
		extra: { event, errorId, status }
	});
	return {
		message: GENERIC_ERROR,
		errorId
	};
}) satisfies HandleClientError;

onFCP(sendVitalsToServer);
onLCP(sendVitalsToServer);
onCLS(sendVitalsToServer);
